import {Injectable, OnDestroy} from '@angular/core';
import {firstValueFrom, Subject} from 'rxjs';

import {UserRoles} from 'app/features/patient/services/patient-utils.service';
import {CaseResourceControllerService, ResourceRoleDto} from '@matchsource/api-generated/common';

@Injectable({providedIn: 'root'})
export class UserRolesService implements OnDestroy {
  private readonly roleCodes: UserRoles[] = [
    UserRoles.Tcc,
    UserRoles.ViewAll,
    UserRoles.CoreCM,
    UserRoles.ElevCM,
    UserRoles.Chs,
    UserRoles.Pe,
    UserRoles.Cp,
    UserRoles.Cpe,
    UserRoles.ViewPart,
  ];
  private readonly destroy$ = new Subject<void>();

  public resourceRoles: ResourceRoleDto[] = [];
  public resourceRoleDescriptions: Record<UserRoles, string>;

  constructor(private readonly caseResourceControllerService: CaseResourceControllerService) {}

  public async fetchResourceRoles(): Promise<void> {
    const resourceRoles = await firstValueFrom(
      this.caseResourceControllerService.getResourceRoles({body: this.roleCodes})
    );

    this.resourceRoles = resourceRoles;
    this.resourceRoleDescriptions = this.buildResourceRoleDescriptionsMap();
  }

  private buildResourceRoleDescriptionsMap(): Record<UserRoles, string> {
    return this.resourceRoles.reduce(
      (accumulator, currentRole) => {
        accumulator[currentRole.code as UserRoles] = currentRole.description;

        return accumulator;
      },
      {} as Record<UserRoles, string>
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
