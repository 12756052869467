import {AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '@matchsource/authentication';
import {switchMap, takeUntil, take, filter} from 'rxjs/operators';
import {from, Observable, Subject, timer} from 'rxjs';
import {RouterState, Navigate} from 'ngxs-ui-router';

import {Select, Store} from '@ngxs/store';
import {BusinessPartiesService} from '@matchsource/store/business-parties';
import {FeatureService} from '@matchsource/feature-toggle';
import {RawParams} from '@uirouter/angular';
import {MsLogoComponent} from '@matchsource/shared/ms-logo';
import {TOKEN_ADDED} from '@nmdp/nmdp-login';
import {WalkmeService} from 'app/shared/services/walkme.service';
import {UserService} from '@matchsource/core';

const HOME_STATE = 'core';

@Component({
  selector: 'ms-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MsLogoComponent],
})
export class LoginComponent implements OnDestroy, AfterViewInit, OnInit {
  readonly windowLocationId: string;
  private readonly destroy$ = new Subject<void>();

  @Select(RouterState.param('returnState'))
  returnState$: Observable<string>;

  @Select(RouterState.param('refreshLogin'))
  refreshLogin$: Observable<boolean>;

  get returnState(): string {
    return this.store.selectSnapshot(RouterState.param('returnState'));
  }

  get refreshLogin(): boolean {
    return this.store.selectSnapshot(RouterState.param('refreshLogin'));
  }

  get params(): RawParams {
    return this.store.selectSnapshot(RouterState.param('params'));
  }

  constructor(
    private readonly auth: AuthService,
    private readonly store: Store,
    private readonly bp: BusinessPartiesService,
    private readonly userService: UserService,
    private readonly feature: FeatureService,
    private readonly walkmeService: WalkmeService
  ) {
    this.windowLocationId = auth.widgetLocationId;
  }

  ngOnInit(): void {
    // When the user's session times out, they get redirected back to /login, but the processes running in the login widget to sign out are not always complete at this time.
    // This code will wait until the user is fully signed out and the widget has assigned a token, and then reload the page.
    this.auth.widget.onEvent
      .pipe(
        filter(event => event.type === TOKEN_ADDED),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        if (this.refreshLogin) {
          window.location.reload();
        }
      });

    // In the event a user logs out, and is going to log in as a different user, we need to unload and reload WalkMe so it updates its internal user roles and ID
    this.walkmeService.unload();
  }

  ngAfterViewInit(): void {
    timer(0).subscribe(() =>
      this.auth.widget
        .showLogin()
        .pipe(
          switchMap(() => this.auth.login()),
          switchMap(() => from(this.feature.load())),
          takeUntil(this.destroy$)
        )
        .subscribe(() => this.login())
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  private login() {
    this.auth.widget.markActive();

    this.userService
      .setBpIds$(this.userService.isCommonPractice() ? this.bp.cpList$ : this.bp.tcList$)
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(() => {
        this.walkmeService.load();
        const nextStateName = this.returnState || HOME_STATE;
        this.store.dispatch(new Navigate(nextStateName, this.params || {}, {reload: true}));
      });
  }
}
